import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Logo from "../../images/logo.png";
import ReactFlagsSelect from "react-flags-select";
import { updateLayout } from "../../../Dashboard/actions/layout";
import { useWindowDimensions } from "../../../Dashboard/utils/useWindowDimensions";
import { FaUser } from "react-icons/fa";

export const NavbarDesktop = (props) => {
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const [dropdownOpen2, setDropdownOpen2] = useState(false);
  const [dropdownOpen3, setDropdownOpen3] = useState(false);
  const { width, height } = useWindowDimensions();

  const toggle1 = () => setDropdownOpen1((prevState) => !prevState);
  const toggle2 = () => setDropdownOpen2((prevState) => !prevState);
  const toggle3 = () => setDropdownOpen3((prevState) => !prevState);

  const [select, setSelect] = useState(null);
  const onSelect = (code) => {
    // setSelect(code);
    props.updateLayout("language", countriesDict[code]);
  };

  const countriesDict = {
    CN: "zh",
    FR: "fr",
    DE: "de",
    IT: "it",
    JP: "ja",
    NL: "nl",
    PT: "pt",
    RU: "ru",
    SA: "ar",
    ES: "es",
    UA: "uk",
    GB: "en",
  };

  useEffect(() => {
    setSelect(
      props.language.includes("-")
        ? props.language.split("-")[1]
        : Object.keys(countriesDict).find(
            (key) => countriesDict[key] === props.language
          )
    );
  }, [props.language]);

  const [fixedHeader, setFixedHeader] = useState("");

  const [currentHome, setCurrentHome] = useState("");
  const [currentService, setCurrentService] = useState("");
  const [currentTeam, setCurrentTeam] = useState("");
  const [currentOffice, setCurrentOffice] = useState("");
  const [currentNews, setCurrentNews] = useState("");
  var str = "foo/bar/test.html";
  var n = str.lastIndexOf("/");
  var result = str.substring(n + 1);
  // {
  //   window.addEventListener("scroll", (event) => {
  //     if (event.path[1].pageYOffset !== 0) {
  //       setFixedHeader("fixed-header");
  //     } else {
  //       setFixedHeader("");
  //     }
  //   });
  // }

  useEffect(() => {
    if (
      props.history.location.pathname
        .substring(props.history.location.pathname.lastIndexOf("/") + 1)
        .includes("homepage")
    ) {
      setCurrentHome("current");
    } else if (
      props.history.location.pathname
        .substring(props.history.location.pathname.lastIndexOf("/") + 1)
        .includes("service")
    ) {
      setCurrentService("current");
    } else if (
      props.history.location.pathname
        .substring(props.history.location.pathname.lastIndexOf("/") + 1)
        .includes("team")
    ) {
      setCurrentTeam("current");
    } else if (
      props.history.location.pathname
        .substring(props.history.location.pathname.lastIndexOf("/") + 1)
        .includes("office")
    ) {
      setCurrentOffice("current");
    } else if (
      props.history.location.pathname
        .substring(props.history.location.pathname.lastIndexOf("/") + 1)
        .includes("news")
    ) {
      setCurrentNews("current");
    } else if (
      props.history.location.pathname
        .substring(props.history.location.pathname.lastIndexOf("/") + 1)
        .includes("privacy_policy") ||
      props.history.location.pathname
        .substring(props.history.location.pathname.lastIndexOf("/") + 1)
        .includes("cookie_policy")
    ) {
      setCurrentOffice("");
      setCurrentTeam("");
      setCurrentHome("");
      setCurrentService("");
      setCurrentNews("");
    }
  }, [props.history.location.pathname]);
  return (
    <div>
      {props.content !== null ? (
        <React.Fragment>
          <header
            className={"main-header " + fixedHeader}
            id="topDiv"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div
              className="row"
              style={{
                height: 90,
                padding: "0px 90px 0px 0px",
                width: "100%",
                backgroundColor:
                  props.history.location.pathname === "/homepage"
                    ? null
                    : props.transparent === true
                    ? null
                    : "#f7faff",
              }}
            >
              <div
                className="col-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Logo}
                  style={{ width: "35px", height: "auto" }}
                  alt=""
                ></img>
                <span
                  style={{
                    marginLeft: 8,
                    fontWeight: "bold",
                    color: "black",
                    fontSize: width < 1300 ? 15 : 17,
                  }}
                >
                  SEVAT GROUP
                  <span
                    style={{
                      position: "relative",
                      top: "-10px",
                      fontSize: "12px",
                    }}
                  >
                    ®
                  </span>
                </span>
              </div>
              <div
                className="col-9"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Dropdown toggle={() => null}>
                  <DropdownToggle
                    className="myDropdownButton"
                    style={
                      props.history.location.pathname === "/homepage"
                        ? { backgroundColor: "white", cursor: "default" }
                        : props.transparent === true
                        ? {
                            paddingTop: 20,
                            backgroundColor: "transparent",
                            cursor: "default",
                          }
                        : {
                            paddingTop: 20,
                            backgroundColor: "#f7faff",
                            cursor: "default",
                          }
                    }
                  >
                    <Link
                      to="/homepage"
                      style={{ color: "#545454", fontSize: 14 }}
                    >
                      {props.content.homepage.toUpperCase()}
                    </Link>
                  </DropdownToggle>
                </Dropdown>
                <Dropdown
                  isOpen={dropdownOpen1}
                  //   toggle={toggle1}
                  toggle={() => null}
                  onMouseEnter={() => toggle1()}
                  onMouseLeave={() => toggle1()}
                >
                  <DropdownToggle
                    caret
                    className="myDropdownButton"
                    style={
                      props.history.location.pathname === "/homepage"
                        ? { backgroundColor: "white", cursor: "default" }
                        : props.transparent === true
                        ? { paddingTop: 20, backgroundColor: "transparent" }
                        : { paddingTop: 20, backgroundColor: "#f7faff" }
                    }
                  >
                    {props.content.services.toUpperCase()}
                  </DropdownToggle>
                  <DropdownMenu className="myDropdownMenu">
                    <DropdownItem className="myDropdownMenuItem">
                      <Link
                        to="/account_service"
                        style={{ color: "white", padding: "5px 0px" }}
                      >
                        {props.content.services1}
                      </Link>
                    </DropdownItem>
                    <DropdownItem className="myDropdownMenuItem">
                      <Link
                        to="/payroll_service"
                        style={{ color: "white", padding: "5px 0px" }}
                      >
                        {props.content.services2}
                      </Link>
                    </DropdownItem>
                    <DropdownItem className="myDropdownMenuItem">
                      <Link
                        to="/ai_service"
                        style={{ color: "white", padding: "5px 0px" }}
                      >
                        {props.content.services3}
                      </Link>
                    </DropdownItem>
                    <DropdownItem className="myDropdownMenuItem">
                      <Link
                        to="/legal_corporate"
                        style={{ color: "white", padding: "5px 0px" }}
                      >
                        {props.content.services4}
                      </Link>
                    </DropdownItem>
                    <DropdownItem className="myDropdownMenuItem">
                      <Link
                        to="/audit"
                        style={{ color: "white", padding: "5px 0px" }}
                      >
                        {props.content.services5}
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>

                {/* Teams */}
                <Dropdown
                  isOpen={dropdownOpen2}
                  //   toggle={toggle2}
                  toggle={() => null}
                  onMouseEnter={() => toggle2()}
                  onMouseLeave={() => toggle2()}
                >
                  <DropdownToggle
                    caret
                    className="myDropdownButton"
                    style={
                      props.history.location.pathname === "/homepage"
                        ? { backgroundColor: "white", cursor: "default" }
                        : props.transparent === true
                        ? { paddingTop: 20, backgroundColor: "transparent" }
                        : { paddingTop: 20, backgroundColor: "#f7faff" }
                    }
                  >
                    {props.content.teams.toUpperCase()}
                  </DropdownToggle>
                  <DropdownMenu className="myDropdownMenu">
                    <DropdownItem className="myDropdownMenuItem">
                      <Link
                        to="/teams"
                        style={{ color: "white", padding: "5px 0px" }}
                      >
                        {props.content.teams1}
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown toggle={() => null}>
                  <DropdownToggle
                    className="myDropdownButton"
                    style={{ paddingTop: 20, backgroundColor: "transparent" }}
                  >
                    <Link
                      to="/partnership"
                      style={{ color: "#545454", fontSize: 14 }}
                    >
                      {props.content.partnership === undefined
                        ? "PARTNERSHIP"
                        : props.content.partnership.toUpperCase()}
                    </Link>
                  </DropdownToggle>
                </Dropdown>
                <Dropdown
                  isOpen={dropdownOpen3}
                  //   toggle={toggle3}
                  toggle={() => null}
                  onMouseEnter={() => toggle3()}
                  onMouseLeave={() => toggle3()}
                >
                  <DropdownToggle
                    caret
                    className="myDropdownButton"
                    style={
                      props.history.location.pathname === "/homepage"
                        ? { backgroundColor: "white", cursor: "default" }
                        : props.transparent === true
                        ? { paddingTop: 20, backgroundColor: "transparent" }
                        : { paddingTop: 20, backgroundColor: "#f7faff" }
                    }
                  >
                    {props.content.offices.toUpperCase()}
                  </DropdownToggle>
                  <DropdownMenu className="myDropdownMenu">
                    <DropdownItem className="myDropdownMenuItem">
                      <Link
                        to="/torino_office"
                        style={{ color: "white", padding: "5px 0px" }}
                      >
                        Torino
                      </Link>
                    </DropdownItem>
                    <DropdownItem className="myDropdownMenuItem">
                      <Link
                        to="/pescara_office"
                        style={{ color: "white", padding: "5px 0px" }}
                      >
                        Pescara
                      </Link>
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
                <Dropdown toggle={() => null}>
                  <DropdownToggle
                    className="myDropdownButton"
                    style={{ paddingTop: 20, backgroundColor: "transparent" }}
                  >
                    <Link to="/news" style={{ color: "#545454", fontSize: 14 }}>
                      {props.content.news.toUpperCase()}
                    </Link>
                  </DropdownToggle>
                </Dropdown>

                <div style={{ marginTop: "5px" }}>
                  <ReactFlagsSelect
                    selected={select}
                    onSelect={onSelect}
                    showSelectedLabel={false}
                    countries={[
                      "CN",
                      "FR",
                      "DE",
                      "IT",
                      "JP",
                      "NL",
                      "PT",
                      "RU",
                      "SA",
                      "ES",
                      "UA",
                      "GB",
                    ]}
                  />
                </div>
                <Dropdown toggle={() => null}>
                  <DropdownToggle
                    className="myDropdownButton"
                    style={{ paddingTop: 20, backgroundColor: "transparent" }}
                  >
                    <Link
                      to="/login"
                      style={{ color: "black", cursor: "pointer" }}
                    >
                      <FaUser style={{ fontSize: 12 }}></FaUser>
                    </Link>
                  </DropdownToggle>
                </Dropdown>
              </div>
            </div>
          </header>
        </React.Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.layout.language,
});

export default connect(mapStateToProps, { updateLayout })(NavbarDesktop);
