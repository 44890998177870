import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { slide as Menu } from "react-burger-menu";
import Collapse from "@material-ui/core/Collapse";
import { Link } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import "./flagsMobile.css";
import { updateLayout } from "../../../Dashboard/actions/layout";
export const NavbarMobile = (props) => {
  const [isOpenCollapse1, setIsOpenCollapse1] = useState(false);
  const [isOpenCollapse2, setIsOpenCollapse2] = useState(false);
  const [isOpenCollapse3, setIsOpenCollapse3] = useState(false);
  const [isOpenCollapse4, setIsOpenCollapse4] = useState(false);

  const toggleCollapse1 = () => setIsOpenCollapse1(!isOpenCollapse1);
  const toggleCollapse2 = () => setIsOpenCollapse2(!isOpenCollapse2);
  const toggleCollapse3 = () => setIsOpenCollapse3(!isOpenCollapse3);
  const toggleCollapse4 = () => setIsOpenCollapse4(!isOpenCollapse4);

  const [select, setSelect] = useState(null);
  const onSelect = (code) => {
    // setSelect(code);
    props.updateLayout("language", countriesDict[code]);
  };

  useEffect(() => {
    setSelect(
      props.language.includes("-")
        ? props.language.split("-")[1]
        : props.language
    );
  }, [props.language]);

  const countriesDict = {
    CN: "zh",
    FR: "fr",
    DE: "de",
    IT: "it",
    JP: "ja",
    NL: "nl",
    PT: "pt",
    RU: "ru",
    SA: "ar",
    ES: "es",
    UA: "uk",
    GB: "en",
  };
  return (
    <div>
      {props.content !== null ? (
        <React.Fragment>
          <header
            className="sticky-header-mobile"
            style={
              props.history.location.pathname === "/homepage"
                ? {
                    boxShadow: "none",
                    backgroundColor: "#fff",
                    // paddingTop: 20,
                  }
                : { boxShadow: "none", backgroundColor: "#f7faff" }
            }
          >
            <Menu>
              <Link to="/homepage">
                <p className="menu-item-burger">{props.content.homepage}</p>
              </Link>
              <p
                className="menu-item-burger"
                onClick={toggleCollapse1}
                style={{ marginTop: 20 }}
              >
                {props.content.services}
                <i className="fa fa-caret-down" style={{ marginLeft: 3 }}></i>
              </p>

              {/* Services */}
              <Collapse in={isOpenCollapse1} timeout="auto" unmountOnExit>
                <Link to="/account_service">
                  <p className="menu-item-burger sub-item">
                    {props.content.services1}
                  </p>
                </Link>
                <Link to="/payroll_service">
                  <p className="menu-item-burger sub-item">
                    {props.content.services2}
                  </p>
                </Link>
                <Link to="/ai_service">
                  <p className="menu-item-burger sub-item">
                    {props.content.services3}
                  </p>
                </Link>
                <Link to="/legal_corporate">
                  <p className="menu-item-burger sub-item">
                    {" "}
                    {props.content.services4}
                  </p>
                </Link>
                <Link to="/audit">
                  <p className="menu-item-burger sub-item">
                    {" "}
                    {props.content.services5}
                  </p>
                </Link>
              </Collapse>

              {/* Teams */}
              <p
                className="menu-item-burger"
                onClick={toggleCollapse2}
                style={{ marginTop: 20 }}
              >
                {" "}
                {props.content.teams}
                <i className="fa fa-caret-down" style={{ marginLeft: 3 }}></i>
              </p>
              <Collapse in={isOpenCollapse2} timeout="auto" unmountOnExit>
                <Link to="/teams">
                  <p className="menu-item-burger sub-item">
                    {" "}
                    {props.content.teams1}
                  </p>
                </Link>
                {/* <Link to="/payroll_team">
              <p className="menu-item-burger sub-item">Payroll Service</p>
            </Link>
            <Link to="/ai_team">
              <p className="menu-item-burger sub-item">
                Artificial Intelligence
              </p>
            </Link>
            <Link to="/legal">
              <p className="menu-item-burger sub-item">Legal</p>
            </Link> */}
              </Collapse>
              <Link to="/partnership">
                <p style={{ marginTop: 20 }} className="menu-item-burger">
                  {props.content.partnership === undefined
                    ? "Partnership"
                    : props.content.partnership}
                </p>
              </Link>
              <p
                className="menu-item-burger"
                onClick={toggleCollapse3}
                style={{ marginTop: 20 }}
              >
                {props.content.offices}
                <i className="fa fa-caret-down" style={{ marginLeft: 3 }}></i>
              </p>
              <Collapse in={isOpenCollapse3} timeout="auto" unmountOnExit>
                <Link to="/torino_office">
                  <p className="menu-item-burger sub-item">Torino</p>
                </Link>
                <Link to="/pescara_office">
                  <p className="menu-item-burger sub-item">Pescara</p>
                </Link>
              </Collapse>
              <Link to="/news">
                <p style={{ marginTop: 20 }} className="menu-item-burger">
                  {props.content.news}
                </p>
              </Link>
              <p
                className="menu-item-burger"
                onClick={toggleCollapse4}
                style={{ marginTop: 20 }}
              >
                Languages
                <i className="fa fa-caret-down" style={{ marginLeft: 3 }}></i>
              </p>
              <Collapse in={isOpenCollapse4} timeout="auto" unmountOnExit>
                <ReactFlagsSelect
                  className="mobile_flags"
                  selected={select}
                  onSelect={onSelect}
                  countries={[
                    "CN",
                    "FR",
                    "DE",
                    "IT",
                    "JP",
                    "NL",
                    "PT",
                    "RU",
                    "SA",
                    "ES",
                    "UA",
                    "GB",
                  ]}
                />
              </Collapse>
              <Link to="/login">
                <p
                  className="menu-item-burger"
                  style={{
                    marginTop: 30,
                    border: "1px solid white",
                    // width: 90,
                    borderRadius: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {props.content.login}
                </p>
              </Link>
            </Menu>
          </header>
        </React.Fragment>
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.layout.language,
});

export default connect(mapStateToProps, { updateLayout })(NavbarMobile);
